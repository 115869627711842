/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: 'RL2', sans-serif;
	--fontFamilySecond: "Comic-Sans", sans-serif;

	--invalidColor: red;
	--primaryColor: #1976d2;
}

html, body {
	font-family: var(--fontFamilyBase);
	min-height: 100%;
	height: 100%;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
	overscroll-behavior: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

p {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.2px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilySecond);
	font-weight: 900;
}

h1 {
	font-size: 40px;
	line-height: 48px;
}

h2 {
    font-size: 26px;
	line-height: 36px;
}

h3 {
    font-size: 20px;
	line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
	font-family: var(--fontFamilyBase);
	font-size: 18px;
	line-height: 20px;
}

h6 {
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}
