@font-face {
	font-family: 'RLFont';
	src: url('./rl/RLFontItalic.woff2') format('woff2'),
			url('./rl/RLFontItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontBlack.woff2") format('woff2'),
			url("./rl/RLFontBlack.woff") format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontBlackItalic.woff2") format('woff2'),
			url("./rl/RLFontBlackItalic.woff") format('woff');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontLightItalic.woff2") format('woff2'),
			url("./rl/RLFontLightItalic.woff") format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontBoldItalic.woff2") format('woff2'),
			url("./rl/RLFontBoldItalic.woff") format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontBold.woff2") format('woff2'),
			url("./rl/RLFontBold.woff") format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontLight.woff2") format('woff2'),
			url("./rl/RLFontLight.woff") format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'RLFont';
	src: url("./rl/RLFontRegular.woff2") format('woff2'),
			url("./rl/RLFontRegular.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro Bold";
    src: url("./sourcesans/SourceSansPro-Bold.woff2") format("woff2"), url("./sourcesans/SourceSansPro-Bold.woff") format("woff"), url("./sourcesans/SourceSansPro-Bold.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family:"Source Sans Pro";
    src:url("./sourcesans/SourceSansPro-Regular.woff2") format("woff2"),url("./sourcesans/SourceSansPro-Regular.woff") format("woff"),url("./sourcesans/SourceSansPro-Regular.otf") format("opentype");
    font-style:normal;font-weight:400;
}
        

@font-face {
    font-family:"Source Sans Pro Semibold";
    src:url("./sourcesans/SourceSansPro-Semibold.woff2") format("woff2"),url("./sourcesans/SourceSansPro-Semibold.woff") format("woff"),url("assets/fonts/sourcesans/SourceSansPro-Semibold.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-Black.woff2') format('woff2');
	font-weight: 900;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-ExtraBold.woff2') format('woff2');
	font-weight: 800;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-Bold.woff2') format('woff2');
	font-weight: 700;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-Medium.woff2') format('woff2');
	font-weight: 500;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-Regular.woff2') format('woff2');
	font-weight: 400;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-Light.woff2') format('woff2');
	font-weight: 300;
}

@font-face {
	font-family: 'RL2';
	src: url('./rl2/RL2-Thin.woff2') format('woff2');
	font-weight: 100;
}